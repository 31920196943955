import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

const instance = axios.create({
  baseURL: 'https://dev-admin.grundoon.inmortal-factory.com:5556/',
});

const instanceDashboard = axios.create({
  baseURL: 'https://dev-app.grundoon.inmortal-factory.com:3800/',
  // baseURL: 'http://localhost:3800',
});

instance.interceptors.request.use((config) => {
  if (!config.headers) {
    config.headers = {};
  }
  config.headers['X-API-Key'] =
    '3c727e33-36f7-49c5-b2d5-1a029d45308f-23fad1c2-06bd-40c6-b698-1e492be25431';
  return config;
});

type HTTPRequestConfig = AxiosRequestConfig;

const api = (axiosI: AxiosInstance) => ({
  get: <T>(url: string, config: HTTPRequestConfig = {}) => axiosI.get<T>(url, config),
  delete: <T>(url: string, config: HTTPRequestConfig = {}) => axiosI.delete<T>(url, config),
  put: <T>(url: string, body: unknown, config: HTTPRequestConfig = {}) =>
    axiosI.put<T>(url, body, config),
  patch: <T>(url: string, body: unknown, config: HTTPRequestConfig = {}) =>
    axiosI.patch<T>(url, body, config),
  post: <T>(url: string, body: unknown, config: HTTPRequestConfig = {}) =>
    axiosI.post<T>(url, body, config),
});

export const Http = api(instance);

export const HttpDashboard = api(instanceDashboard);
