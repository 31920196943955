// @mui
import { Stack, Typography, Link as MuiLink } from '@mui/material';
// react-router-dom
import { Link as RouterLink } from 'react-router-dom';

// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">Sign in to NewsKiosk</Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">New user?</Typography>

          <MuiLink component={RouterLink} to="/register" variant="subtitle2">
            Create an account
          </MuiLink>
        </Stack>
      </Stack>

      <AuthLoginForm />
    </LoginLayout>
  );
}
