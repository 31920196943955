// i18n
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './Presentation/locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// editor
import 'react-quill/dist/quill.snow.css';
// ----------------------------------------------------------------------

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './Presentation/routes';
// theme
import ThemeProvider from './Presentation/theme';
// locales
import ThemeLocalization from './Presentation/locales';
// components
import SnackbarProvider from './Presentation/components/snackbar';
import ScrollToTop from './Presentation/components/scroll-to-top';
import { MotionLazyContainer } from './Presentation/components/animate';
import { ThemeSettings, SettingsProvider } from './Presentation/components/settings';
// Check our docs
// https://docs.minimals.cc/authentication/ts-version

import { AuthProvider } from './Presentation/auth/JwtContext';
import { SearchContextProvider } from './Presentation/context/SearchContext';
import { AlertNotificationContextProvider } from './Presentation/context/AlertNotificationContext';

const queryClient = new QueryClient();

// ----------------------------------------------------------------------

export default function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <SettingsProvider>
          <AlertNotificationContextProvider>
            <BrowserRouter>
              <ScrollToTop />
              <MotionLazyContainer>
                <ThemeProvider>
                  <ThemeSettings>
                    <ThemeLocalization>
                      <SnackbarProvider>
                        <QueryClientProvider client={queryClient}>
                          <SearchContextProvider>
                            <Router />
                          </SearchContextProvider>
                        </QueryClientProvider>
                      </SnackbarProvider>
                    </ThemeLocalization>
                  </ThemeSettings>
                </ThemeProvider>
              </MotionLazyContainer>
            </BrowserRouter>
          </AlertNotificationContextProvider>
        </SettingsProvider>
      </HelmetProvider>
    </AuthProvider>
  );
}
