// @mui
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Box, BoxProps, Link, Typography, Card, Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// config
import { HEADER } from '../../../config-global';
// utils
import { bgBlur } from '../../utils/cssStyles';
// components
import Logo from '../../components/logo';
import { PATH_AUTH } from '../../routes/paths';
import { useSettingsContext } from '../../components/settings';

// ----------------------------------------------------------------------

type Props = {
  isOffset: boolean;
};

export default function Header({ isOffset }: Props) {
  const { onSetCurrentArticle, onSetCollapseAllTopics, collapseAllTopics, currentMetaInfoArticle } =
    useSettingsContext();
  const theme = useTheme();
  return (
    <AppBar color="transparent" sx={{ boxShadow: 0 }}>
      <Toolbar
        sx={{
          justifyContent: 'flex-start',
          flexDirection: 'column',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            margin: '10px',
            marginBottom: '0px',
          }}
        >
          <Logo />

          {currentMetaInfoArticle && (
            <Typography
              variant="h4"
              sx={{
                color: 'text.secondary',
              }}
            >
              {currentMetaInfoArticle.title}
            </Typography>
          )}

          <div>
            <Link to={PATH_AUTH.login} component={RouterLink} variant="subtitle2" color="inherit">
              Log In /
            </Link>
            <Link
              to={PATH_AUTH.register}
              component={RouterLink}
              variant="subtitle2"
              color="inherit"
            >
              Register
            </Link>
          </div>
        </div>
        {currentMetaInfoArticle && (
          <>
            <Typography
              variant="h6"
              sx={{
                color: 'text.secondary',
              }}
            >
              Save as: {currentMetaInfoArticle.searchName}
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                margin: '2px',
                marginBottom: '10px',
              }}
            >
              {currentMetaInfoArticle.source} - {currentMetaInfoArticle.publishedDate} -{' '}
              {currentMetaInfoArticle.author}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              {/*
                <Card variant="outlined" style={{ marginRight: '100px' }}>
                  <Button onClick={() => onSetCurrentArticle(-1)} size="medium" color="primary" aria-label="previous">
                    <ArrowBackIosIcon />
                    Previous Article
                  </Button>
                  <Button onClick={() => onSetCurrentArticle(+1)} size="medium" color="primary" aria-label="add">
                    Next Article
                    <ArrowForwardIosIcon />
                  </Button>
                </Card>
                */}
              <Card variant="outlined">
                <Button
                  onClick={onSetCollapseAllTopics}
                  size="medium"
                  color="primary"
                  aria-label="expand all"
                >
                  {!collapseAllTopics ? 'Expand All Sentences' : 'Collapse All Sentences'}
                </Button>
              </Card>
            </div>
          </>
        )}
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
