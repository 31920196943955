import { Term } from 'src/Domain/Models/Term';
import { Http } from '../../../Services/Http';

export interface TermDataSource {
  // eslint-disable-next-line no-unused-vars
  getTermDetected(term: string): Promise<Term>;
}

export class TermDataSourceImpl implements TermDataSource {
  // eslint-disable-next-line class-methods-use-this
  async getTermDetected(term: string): Promise<Term> {
    const res = await Http.get<Term>(`/v2/detect/${term}`);
    return res.data;
  }
}
