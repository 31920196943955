import { TermRepository } from 'src/Domain/Repositories/TermRepository';
import { Term } from 'src/Domain/Models/Term';
import { TermDataSource } from '../DataSources/Term/TermDataSource';

export class TermRepositoryImpl implements TermRepository {
  dataSource: TermDataSource;

  constructor(dataSource: TermDataSource) {
    this.dataSource = dataSource;
  }

  async getTermDetected(term: string): Promise<Term> {
    return this.dataSource.getTermDetected(term);
  }
}
