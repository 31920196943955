import Logo from 'src/Presentation/components/logo';
import { Box } from '@mui/material';
import AutocompleteSectorsAndIndustries from './AutocompleteSectorsAndIndustries';
import SearchTerms from './SearchTerms';
import AccountPopover from '../AccountPopover';
import TermsMatched from './TermsMatched';

export default function HeaderMainPageDesktop() {
  return (
    <Box
      gap={2}
      display="grid"
      gridTemplateColumns={{ md: '0.5fr 1.5fr 1.5fr 2fr 0.5fr' }}
      sx={{ ml: 2, mt: 2, mb: 8 }}
    >
      <Logo />

      <AutocompleteSectorsAndIndustries />

      <SearchTerms />

      <TermsMatched />

      <AccountPopover />
    </Box>
  );
}
