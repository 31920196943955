import { createContext, useMemo, useState } from 'react';

interface SelectedTermsContextType {
  selectedTerms: string[];
  setSelectedTerms: React.Dispatch<React.SetStateAction<string[]>>;
}

const defaultValue: SelectedTermsContextType = {
  selectedTerms: [],
  setSelectedTerms: () => {},
};

export const SelectedTermsContext = createContext(defaultValue);

interface SelectedTermsContextProviderProps {
  children: React.ReactNode;
}

export const SelectedTermsContextProvider = (props: SelectedTermsContextProviderProps) => {
  const { children } = props;
  const [selectedTermsState, setSelectedTermsState] = useState<string[]>(
    defaultValue.selectedTerms
  );

  const contextValue = useMemo(
    () => ({
      selectedTerms: selectedTermsState,
      setSelectedTerms: setSelectedTermsState,
    }),
    [selectedTermsState]
  );

  return (
    <SelectedTermsContext.Provider value={contextValue}>{children}</SelectedTermsContext.Provider>
  );
};
