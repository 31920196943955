import { CategorizationDataSource } from "../DataSources/Categorization/CategorizationDataSource";
import { CategorizationRepository } from "../../Domain/Repositories/CategorizationRepository";
import {Categorization} from "../../Domain/Models/Categorization";

export class CategorizationRepositoryImpl implements CategorizationRepository {
    datasource: CategorizationDataSource;

    constructor(datasource: CategorizationDataSource) {
        this.datasource = datasource;
    }

    async getCategorizations(): Promise<Categorization[]> {
        return this.datasource.getCategorizations();
    }

}