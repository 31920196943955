import { Autocomplete, Box, TextField } from '@mui/material';
import { useContext, useEffect } from 'react';
import { CategorizationDataSourceImpl } from 'src/Data/DataSources/Categorization/CategorizationDataSource';
import { CategorizationRepositoryImpl } from 'src/Data/Repositories/CategorizationRepositoryImpl';
import { useFetchCategorizations } from 'src/Domain/UseCases/useFetchCategorization';
import { SearchContext } from 'src/Presentation/context/SearchContext';

export default function AutocompleteSectorsAndIndustries() {
  const { search, setSearch } = useContext(SearchContext);

  const { categorizations } = useFetchCategorizations(
    new CategorizationRepositoryImpl(new CategorizationDataSourceImpl())
  );

  const sectors: any[] = [];
  categorizations?.forEach((sector) => {
    sectors.push({ label: sector.sector.name, value: sector.sector._id });
    sector.industries.forEach((industryItem) => {
      sectors.push({
        label: industryItem.name,
        value: industryItem._id,
        sector_ref: industryItem.sector_ref,
      });
    });
  });

  const handleOpen = () => {
    setSearch((prevState) => ({
      ...prevState,
      industry: {
        ...prevState.industry,
        openOptions: true,
      },
    }));
  };
  const handleChangeIndustry = (event: any, value: any) => {
    if (!value) {
      setSearch((prevState) => ({
        ...prevState,
        industry: {
          ...prevState.industry,
          label: '',
          sector_ref: '',
          value: '',
          openOptions: true,
        },
      }));
    } else if (value.value !== search.industry.value) {
      setSearch((prevState) => ({
        ...prevState,
        industry: {
          ...prevState.industry,
          label: value.label,
          sector_ref: value.sector_ref,
          value: value.value,
          openOptions: false,
        },
        page: 1,
      }));
    }
  };

  const handleClose = () => {
    setSearch((prevState) => ({
      ...prevState,
      industry: {
        ...prevState.industry,
        openOptions: false,
      },
    }));
  };
  const defaultValue = sectors.find((s) => s.value === search.industry.value) || null;

  useEffect(() => {
    setSearch((prevState) => ({
      ...prevState,
      industry: {
        ...prevState.industry,
        openOptions: !search.industry.value,
      },
    }));
  }, [search.industry.value]);

  return (
    <Autocomplete
      disablePortal
      id="combo-box-industry"
      options={sectors}
      sx={{ ml: 2, mt: 1, mr: 2 }}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      size="small"
      onChange={handleChangeIndustry}
      onFocus={handleOpen}
      onBlur={handleClose}
      onClose={handleClose}
      onOpen={handleOpen}
      open={search.industry.openOptions}
      value={defaultValue}
      renderOption={(props, option) => (
        <Box component="li" sx={{ p: 0, m: 0 }} {...props}>
          {option.sector_ref !== undefined ? (
            <span style={{ fontSize: 12 }}>{option.label}</span>
          ) : (
            <b>{option.label}</b>
          )}
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label="Sectors and Industries" />}
    />
  );
}
