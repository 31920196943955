import { Outlet } from 'react-router-dom';
// @mui
import { Stack, Container } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// config
import { HEADER } from '../../../config-global';
//
import Header from './Header';

// ----------------------------------------------------------------------

interface CompactLayoutProps {
  children: React.ReactNode;
}

// eslint-disable-next-line react/prop-types
const CompactLayout: React.FC<CompactLayoutProps> = ({ children }) => {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);
  console.log('children', children);
  return (
    <>
      <Header isOffset={isOffset} />

      <Container component="main" maxWidth={false}>
        <Stack
          sx={{
            py: 12,
            m: 'auto',
            maxWidth: '100%',
            minHeight: '100vh',
            textAlign: 'left',
            justifyContent: 'center',
          }}
        >
          <Outlet />
        </Stack>
      </Container>
    </>
  );
};
export default CompactLayout;
