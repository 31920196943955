import { useQuery } from '@tanstack/react-query';
import { TermRepositoryImpl } from 'src/Data/Repositories/TermRepositoryImpl';
import { Term } from '../Models/Term';

export const useFetchDetectTerm = (term: string, repository: TermRepositoryImpl) => {
  const { data, isLoading } = useQuery<Term>({
    queryKey: ['detectTerm', term],
    queryFn: () => repository.getTermDetected(term),
    enabled: !!term,
    retry: true,
  });
  return {
    termDetected: data,
    isFetchDetectTermLoading: isLoading,
  };
};
