import { Typography, Stack } from '@mui/material';
import Image from '../../components/image';
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children, illustration, title }: Props) {
  return (
    <StyledRoot>
      <StyledSection>
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={4}>
          <Typography variant="h3" sx={{ mb: 2, maxWidth: 580, textAlign: 'center' }}>
            {title || 'Welcome to what we have to offer...'}
          </Typography>
        </Stack>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Image
            disabledEffect
            visibleByDefault
            alt="auth"
            src={illustration || '/assets/illustrations/login.png'}
            sx={{ maxWidth: 720, height: 500 }}
          />
        </div>

        <StyledSectionBg />
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
