import React from 'react';
import { Outlet } from 'react-router-dom';
import { Stack, Container } from '@mui/material';

interface FirstEntryPageLayoutProps {
  children: React.ReactNode;
}

const FirstEntryPageLayout: React.FC<FirstEntryPageLayoutProps> = ({ children }) => (
  <Container component="main" maxWidth={false}>
    <Stack
      sx={{
        m: 'auto',
        maxWidth: '100%',
        // minHeight: '100vh',
        textAlign: 'left',
        justifyContent: 'center',
      }}
    >
      <Outlet />
    </Stack>
  </Container>
);

export default FirstEntryPageLayout;
