import { Box, Chip, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SelectedTermsContext } from 'src/Presentation/context/SelectedTermContexts';
import { useContext, useEffect } from 'react';
import { SearchContext } from 'src/Presentation/context/SearchContext';

export default function TermsMatched() {
  const { selectedTerms, setSelectedTerms } = useContext(SelectedTermsContext);

  const { search } = useContext(SearchContext);

  const handleDeleteChip = (chipToDelete: string) => () => {
    setSelectedTerms((prevState) => prevState.filter((chip) => chip !== chipToDelete));
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    setSelectedTerms((prevState) => search.terms);
  }, []);

  return (
    <Box>
      {selectedTerms.length > 0 && (
        <Stack direction="column" spacing={1} sx={{ mt: 1, ml: 2 }}>
          <Stack direction="row" spacing={1}>
            {selectedTerms.map((term, index) => (
              <Chip
                key={index}
                label={term}
                color="primary"
                onDelete={handleDeleteChip(term)}
                deleteIcon={
                  <IconButton size="small" aria-label="delete">
                    <CloseIcon sx={{ fontSize: 15 }} />
                  </IconButton>
                }
              />
            ))}
          </Stack>
        </Stack>
      )}
    </Box>
  );
}
