import { AlertColor } from '@mui/material';
import { Dispatch, SetStateAction, createContext, useMemo, useState } from 'react';

interface AlertNotificationState {
  showAlert: boolean;
  message: string;
  severity: AlertColor;
}

interface AlertNotificationStateContextType {
  alertNotification: AlertNotificationState;
  setAlertNotification: Dispatch<SetStateAction<AlertNotificationState>>;
}

const defaultValue: AlertNotificationStateContextType = {
  alertNotification: {
    showAlert: false,
    message: '',
    severity: 'success',
  },
  setAlertNotification: () => {},
};

interface AlertNotificationContextProviderProps {
  children: React.ReactNode;
}

const AlertNotificationContext = createContext(defaultValue);

export const AlertNotificationContextProvider = ({
  children,
}: AlertNotificationContextProviderProps) => {
  const [alertNotificationState, setAlertNotificationState] = useState<AlertNotificationState>(
    defaultValue.alertNotification
  );

  const contextValue = useMemo(
    () => ({
      alertNotification: alertNotificationState,
      setAlertNotification: setAlertNotificationState,
    }),
    [alertNotificationState]
  );

  return (
    <AlertNotificationContext.Provider value={contextValue}>
      {children}
    </AlertNotificationContext.Provider>
  );
};

export default AlertNotificationContext;
