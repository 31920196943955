import { useQuery } from "@tanstack/react-query";
import { Categorization } from "../Models/Categorization";
import { CategorizationRepositoryImpl } from "../../Data/Repositories/CategorizationRepositoryImpl";

export const useFetchCategorizations = (repository: CategorizationRepositoryImpl) => {
    const { data, isLoading } = useQuery<Categorization[]>({
        queryKey: ["categorizations"],
        queryFn: () => repository.getCategorizations(),
    });

    return {
        categorizations: data,
        isFetchCategorizationsLoading: isLoading,
    };
};