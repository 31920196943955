import { Categorization } from "../../../Domain/Models/Categorization";
import { Http } from "../../../Services/Http";
import {CategorizationApiDTO} from "../../DTOs/CategorizationApiDTO";

export interface CategorizationDataSource {
    getCategorizations(): Promise<Categorization[]>;
}

export class CategorizationDataSourceImpl implements CategorizationDataSource {
    /* eslint-disable */
    async getCategorizations() {
        const res = await Http.get<CategorizationApiDTO>("/info/categorization");
        return res.data?.categorization;
    }
}